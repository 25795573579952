export default {
  proxy_path: "https://ooz6i1tr5d.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://u9znjlh1n1.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4",
  main_company_term_id: "6",
  crm_base_url: "ausgrid.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.ausgrid.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.ausgrid.forwoodsafety.com",
    APP_CLIENT_ID: "140813crn6pgrr4msbp9p4504g",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://wqf1wrfzb9.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.ausgrid.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
